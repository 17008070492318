import React, { useEffect, useState } from "react";
import "./paymentinformation.scss";
import { CiWallet } from "react-icons/ci";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const PaymentInformation = ({ loanNum }) => {
  const [data, setData] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [averagePayment, setAveragePayment] = useState(0);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  };

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      try {
        const [
          paymentInfoRes,
          paymentTotalRes,
          paymentAverageRes,
        ] = await Promise.all([
          axios.get(`${SERVER_URL}/payment/info/${loanNum}`),
          axios.get(`${SERVER_URL}/payment/total/${loanNum}`),
          axios.get(`${SERVER_URL}/payment/average/${loanNum}`),
        ]);

        setData(paymentInfoRes.data);
        setTotalPayment(paymentTotalRes.data[0].TotalPayment);
        setAveragePayment(paymentAverageRes.data[0].AveragePayment);
      } catch (error) {
        console.error("Unable to fetch payment information!", error.message);
      }
    };

    if (loanNum) {
      fetchPaymentInfo();
    }
  }, [loanNum]);

  return (
    <>
      <div className="payment-heading">
        <h1>
          <CiWallet className="icon" /> Payment Information
        </h1>
      </div>
      <div className="payment-details-container">
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Payment Date</th>
                <th>Payment Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.map((payment, index) => (
                <tr key={index}>
                  <td>{new Date(payment.PaymentDate).toLocaleDateString()}</td>
                  <td>{formatNumber(payment.PaymentAmount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="progress-bar-container">
          <div className="total-payment">
            <h4>Total Payment</h4>
            <h1>{formatNumber(totalPayment)}</h1>
          </div>
          <div className="total-payment">
            <h4>Average Payment</h4>
            <h1>{formatNumber(averagePayment)}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentInformation;
