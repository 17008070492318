import React, { useEffect, useState } from "react";
import "./loanapplication.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const LoanApplication = ({ selectedDPDGroup, setSelectedLoanNum }) => {
  const [loanNumbers, setLoanNumbers] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState("");

  useEffect(() => {
    const fetchLoanNumbers = async () => {
      if (selectedDPDGroup && selectedDPDGroup !== "Select") {
        try {
          const response = await axios.get(
            `${SERVER_URL}/loan/${selectedDPDGroup}`
          );
          setLoanNumbers(response.data);
          setSelectedLoan("");
          setSelectedLoanNum("");
        } catch (error) {
          console.error("Unable to fetch the loan numbers", error.message);
        }
      } else {
        setLoanNumbers([]);
        setSelectedLoan("");
        setSelectedLoanNum("");
      }
    };
    fetchLoanNumbers();
  }, [selectedDPDGroup, setSelectedLoanNum]);

  const handleLoanChange = (e) => {
    setSelectedLoan(e.target.value);
    setSelectedLoanNum(e.target.value);
  };

  return (
    <div className="loan-application-details">
      <h1>Loan Application Viewer</h1>
      <p>Select Loan Application Number</p>
      <select
        name="loanNum"
        id="loanNum"
        value={selectedLoan}
        onChange={handleLoanChange}
      >
        <option value="">Select</option>
        {loanNumbers.map((loan, index) => (
          <option key={index} value={loan.LoanNum}>
            {loan.LoanNum}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LoanApplication;
