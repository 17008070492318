import React, { useEffect, useState } from "react";
import "./customerinformation.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { GrContactInfo } from "react-icons/gr";

const CustomerInformation = ({ loanNum }) => {
  const [data, setData] = useState({
    LoanNum: "",
    CarMake: "",
    AnnualIncome: 0,
    CarPrice: 0,
  });

  useEffect(() => {
    const fetchCustomerInformation = async () => {
      if (!loanNum) return;
      try {
        const response = await axios.get(`${SERVER_URL}/customer/${loanNum}`);
        if (response.data && response.data.length > 0) {
          setData(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching customer information:", error.message);
      }
    };

    fetchCustomerInformation();
  }, [loanNum]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  };

  return (
    <>
      <div className="customer-heading">
        <h1>
          <GrContactInfo className="icon" /> Customer Information
        </h1>
      </div>
      <div className="customer-details-info">
        <ul>
          <li>
            <p>Customer ID: {data.LoanNum}</p>
          </li>
          <li>
            <p>
              Employment: <span>Employed</span>
            </p>
          </li>
          <li>
            <p>Car Make: {data.CarMake}</p>
          </li>
        </ul>
        <ul>
          <li>
            <p>Annual Income: {formatNumber(data.AnnualIncome)}</p>
          </li>
          <li>
            <p>
              Car Value: <span>{formatNumber(data.CarPrice)}</span>
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CustomerInformation;
