import React, { useEffect, useState } from "react";
import "./loaninformation.scss";
import { FaHandHoldingDollar } from "react-icons/fa6";
import RemainingBalance from "./RemainingBalance";
import axios from "axios";
import { SERVER_URL } from "../../utils/helper";

const LoanInformation = ({ loanNum }) => {
  const [data, setData] = useState({
    InterestRate: 0,
    LoanAmount: 0,
    LoanTerm: 0,
    InstallmentAmount: 0,
    LoanStatus: "",
    TotalPayment: 0,
    RemainingBalance: 0,
  });

  const [paymentPercentage, setPaymentPercentage] = useState(0);

  useEffect(() => {
    const fetchLoanInfo = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/loan/info/${loanNum}`);
        if (response.data && response.data.length > 0) {
          setData(response.data[0]);
        } else {
          console.error("No data found for the provided loan number.");
        }
      } catch (error) {
        console.error("Unable to fetch loan information!", error.message);
      }
    };
    fetchLoanInfo();
  }, [loanNum]);

  useEffect(() => {
    if (data && data.LoanAmount > 0) {
      const percentage = (data.TotalPayment / data.LoanAmount) * 100;
      setPaymentPercentage(Math.round(percentage));
    }
  }, [data]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  };

  return (
    <>
      <div className="loan-heading">
        <h1>
          <FaHandHoldingDollar className="icon" /> Loan Information
        </h1>
      </div>
      <div className="loan-details-info">
        <ul>
          <li>
            <p>Interest Rate: {data && `${data.InterestRate}%`}</p>
          </li>
          <li>
            <p>Loan Amount: {data && formatNumber(data.LoanAmount)}</p>
          </li>
          <li>
            <p>Loan Term: {data && data.LoanTerm} Months</p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Installment Amount: {data && formatNumber(data.InstallmentAmount)}
            </p>
          </li>
          <li>
            <p>Status: {data && <span>{data.LoanStatus}</span>}</p>
          </li>
        </ul>
        <div className="progress-bar">
          <p>
            Remaining Balance: {data && formatNumber(data.RemainingBalance)}
          </p>
          <RemainingBalance percentage={paymentPercentage} />
        </div>
      </div>
    </>
  );
};

export default LoanInformation;
