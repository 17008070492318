import React, { useEffect, useRef, useState } from "react";
import "./dashboard.scss";
import UserIcon from "../../components/sidebar/UserIcon";
import DateTime from "../../components/sidebar/DateTime";
import Delinquent from "../../components/sidebar/Delinquent";
import LoanApplication from "../../components/sidebar/LoanApplication";
import CustomerInformation from "../../components/dashboard/CustomerInformation";
import LoanInformation from "../../components/dashboard/LoanInformation";
import PaymentInformation from "../../components/dashboard/PaymentInformation";
import CallInformation from "../../components/dashboard/CallInformation";
import DelinquentInformation from "../../components/dashboard/DelinquentInformation";
import { RiShutDownLine } from "react-icons/ri";
import { CgBot } from "react-icons/cg";
import { IoIosArrowDown } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  signOutUserStart,
  signOutUserFailure,
  signOutUserSuccess,
} from "../../redux/userSlice";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [selectedDPDGroup, setSelectedDPDGroup] = useState("");
  const [selectedLoanNum, setSelectedLoanNum] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [runId, setRunId] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const chatEndRef = useRef(null);

  const handleSignOut = async () => {
    try {
      dispatch(signOutUserStart());
      const res = await axios.post(`${SERVER_URL}/user/logout`, {
        id: currentUser?._id,
      });
      const data = await res.data;
      if (!data || res.status === 500) {
        dispatch(signOutUserFailure(data.message));
        return;
      }
      dispatch(signOutUserSuccess(data));
      localStorage.clear();
      Cookies.remove("access_token");
      <Navigate to="/login" />;
    } catch (error) {
      dispatch(signOutUserFailure(error.message));
    }
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const sendMessage = async () => {
    if (!message.trim()) return;

    try {
      const res = await axios.post(
        "https://dasceqchatbotapi.dasceq.com/send_message/",
        { user_message: message },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { thread_id, run_id } = res.data;
      setThreadId(thread_id);
      setRunId(run_id);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { user: "You", text: message },
      ]);
      setMessage("");
      setIsPolling(true);
    } catch (error) {
      console.error("Error sending message:", error);
      setError("Failed to send message");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  useEffect(() => {
    let intervalId;

    const fetchResponse = async () => {

      if (threadId && runId && isPolling) {
        try {
          const res = await axios.get(
            `https://dasceqchatbotapi.dasceq.com/get_response/?thread_id=${threadId}&run_id=${runId}`
          );
          const { response } = res.data;

          if (response) {
            setChatMessages((prevMessages) => [
              ...prevMessages,
              { user: "DASCEQ AI", text: response },
            ]);
          }

          setThreadId(null);
          setRunId(null);
          setIsPolling(false);
          
          clearInterval(intervalId);
        } catch (error) {
          console.error("Error fetching response:", error);
          setIsPolling(false);
          console.log("State after error:", { threadId, runId, isPolling });
          clearInterval(intervalId);
        }
      }
    };

    if (isPolling) {
      intervalId = setInterval(fetchResponse, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [threadId, runId, isPolling]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  return (
    <div className="dashboard-container">
      <div className="left-group">
        <div className="action-group">
          <div className="user-icon-container">
            <UserIcon />
          </div>
          <hr className="separator" />
          <div className="date-time-container">
            <DateTime />
          </div>
          <div className="delinquent-container">
            <Delinquent
              selectedDPDGroup={selectedDPDGroup}
              setSelectedDPDGroup={setSelectedDPDGroup}
            />
          </div>
          <div className="loan-application-container">
            <LoanApplication
              selectedDPDGroup={selectedDPDGroup}
              setSelectedLoanNum={setSelectedLoanNum}
            />
          </div>
        </div>
      </div>
      <div className="right-group">
        <div className="top-header-container">
          <h1>dasceq agent dashboard</h1>
          <button className="btn-logout" onClick={handleSignOut}>
            <RiShutDownLine className="icon" /> Logout
          </button>
        </div>
        <div className="customer-information-container">
          <CustomerInformation loanNum={selectedLoanNum} />
        </div>
        <div className="loan-information-container">
          <LoanInformation loanNum={selectedLoanNum} />
        </div>
        <div className="other-details-container">
          <div className="payment-information-container">
            <PaymentInformation loanNum={selectedLoanNum} />
          </div>
          <div className="call-information-container">
            <CallInformation loanNum={selectedLoanNum} />
          </div>
          <div className="delinquent-information-container">
            <DelinquentInformation loanNum={selectedLoanNum} />
          </div>
        </div>
      </div>
      <div className="chatbot-icon" onClick={toggleChat}>
        <CgBot className="chat-icon" />
      </div>
      {isChatOpen && (
        <div className="chat-popup">
          <div className="chat-popup-header">
            <div className="chat-popup-heading-container">
              <CgBot className="chat-popup-header-icon" />
              <div className="popup-header-heading">
                <p>chat with</p>
                <h1>Dasceq AI</h1>
              </div>
            </div>
            <button className="close-btn" onClick={toggleChat}>
              <IoIosArrowDown />
            </button>
          </div>
          <div className="chat-popup-body">
            <div className="chat-popup-body-top">
              <p>We typically reply in a few moments</p>
            </div>
            <div className="chat-popup-body-chat-container">
              {chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`chat-message ${
                    msg.user === "You" ? "user-message" : "ai-message"
                  }`}
                >
                  <strong>{msg.user}: </strong>
                  {msg.text}
                </div>
              ))}
              <div ref={chatEndRef} />
            </div>
            <div className="chat-popup-body-bottom">
              <form
                method="POST"
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <input
                  type="text"
                  id="message"
                  name="message"
                  placeholder="Enter your message..."
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button type="submit">
                  <IoSend className="send-icon" />
                </button>
              </form>
              {error && (
                <p
                  style={{
                    color: "red",
                    display: "flex",
                    width: "100%",
                    margin: 0,
                    textAlign: "center",
                    justifyContent: "center",
                    padding: "10px 0px",
                  }}
                >
                  {error}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
