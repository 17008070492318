import React, { useState } from "react";
import "./login.scss";
import PasswordInput from "../../components/password-input/PasswordInput";
import EmailInput from "../../components/email-input/EmailInput";
import { CiLogin } from "react-icons/ci";
import Cookies from "js-cookie";
import axios from "axios";
import { SERVER_URL } from "../../utils/helper";
import { useDispatch } from "react-redux";
import {
  signInStart,
  signInSuccess,
  signInFailure,
} from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      dispatch(signInStart());
      const res = await axios.post(`${SERVER_URL}/user/login`, {
        email: formData.email,
        password: formData.password,
      });
      const data = await res.data;
      if (res.status === 200) {
        dispatch(signInSuccess(data));
        Cookies.set("access_token", data._id);
        setError(null);
        setLoading(false);
        navigate("/");
      } else {
        throw new Error(data.message || "Login failed");
      }
    } catch (error) {
      console.error("Login error", error);
      setError(error.response?.data?.message || error.message);
      dispatch(signInFailure(error.message));
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <form method="POST" onSubmit={handleSubmit}>
        <h2>
          <CiLogin />
          Agent Dashboard Login
        </h2>
        <EmailInput
          name="email"
          id="email"
          type="email"
          placeholder="Email"
          autoComplete="on"
          value={formData.email}
          onChange={handleChange}
        />
        <PasswordInput
          name="password"
          id="password"
          placeholder="Password"
          autoComplete="on"
          value={formData.otp}
          onChange={handleChange}
        />
        <button className="btn-login" type="submit">
          {loading ? "Logging in..." : "Login"}
        </button>
        {error && <p style={{ color: "#ff0000" }}>{error}</p>}
      </form>
    </div>
  );
};

export default Login;
