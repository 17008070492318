import React, { useEffect, useState } from "react";
import "./delinquentinformation.scss";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const DelinquentInformation = ({ loanNum }) => {
  const [data, setData] = useState([]);
  const [averageDPD, setAverageDPD] = useState(0);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  };

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      try {
        const [delinquentInfoRes, delinquentAverageRes] =
          await Promise.all([
            axios.get(`${SERVER_URL}/delinquent/info/${loanNum}`),
            axios.get(`${SERVER_URL}/delinquent/average/${loanNum}`),
          ]);

        setData(delinquentInfoRes.data);
        setAverageDPD(delinquentAverageRes.data[0].AverageDPD);
      } catch (error) {
        console.error("Unable to fetch payment information!", error.message);
      }
    };

    if (loanNum) {
      fetchPaymentInfo();
    }
  }, [loanNum]);

  return (
    <>
      <div className="delinquency-heading">
        <h1>
          <FaHandHoldingDollar className="icon" /> Delinquency Information
        </h1>
      </div>
      <div className="delinquency-details-container">
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Delinquent Date</th>
                <th>Delinquent Amount</th>
                <th>DPD</th>
              </tr>
            </thead>
            <tbody>
              {data.map((delinquent, index) => (
                <tr key={index}>
                  <td>
                    {new Date(delinquent.DeliquentDate).toLocaleDateString()}
                  </td>
                  <td>{formatNumber(delinquent.DeliquentAmount)}</td>
                  <td>{delinquent.DPD}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="progress-bar-container">
          <div className="total-payment">
            <h4>Average DPD</h4>
            <h1>{averageDPD}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default DelinquentInformation;
