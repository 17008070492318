// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-heading {
  display: flex;
  flex-direction: column;
}
.customer-heading h1 {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  color: #003399;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
}
.customer-heading h1 .icon {
  height: 26px;
  width: 26px;
}
.customer-heading h1::after {
  content: "";
  flex-grow: 1;
  height: 2px;
  background-color: #003399;
  margin-left: 10px;
  margin-top: 20px;
}

.customer-details-info {
  width: 100%;
  height: max-content;
  display: flex;
  gap: 80px;
  background: #dae1ff;
  padding: 20px;
}
.customer-details-info ul {
  list-style: none;
  line-height: 2;
}
.customer-details-info ul li p {
  display: flex;
  gap: 10px;
  color: #545454;
  text-transform: capitalize;
}
.customer-details-info ul li p span {
  color: #004aad;
  text-transform: capitalize;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/customerinformation.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACI;EACE,YAAA;EACA,WAAA;AACN;AAEI;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,iBAAA;EACA,gBAAA;AAAN;;AAKA;EACE,WAAA;EACA,mBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,aAAA;AAFF;AAIE;EACE,gBAAA;EACA,cAAA;AAFJ;AAII;EACE,aAAA;EACA,SAAA;EACA,cAAA;EACA,0BAAA;AAFN;AAIM;EACE,cAAA;EACA,0BAAA;EACA,gBAAA;AAFR","sourcesContent":[".customer-heading {\n  display: flex;\n  flex-direction: column;\n\n  h1 {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    text-transform: uppercase;\n    color: #003399;\n    font-size: 26px;\n    font-weight: 700;\n    letter-spacing: 1px;\n\n    .icon {\n      height: 26px;\n      width: 26px;\n    }\n\n    &::after {\n      content: \"\";\n      flex-grow: 1;\n      height: 2px;\n      background-color: #003399;\n      margin-left: 10px;\n      margin-top: 20px;\n    }\n  }\n}\n\n.customer-details-info {\n  width: 100%;\n  height: max-content;\n  display: flex;\n  gap: 80px;\n  background: #dae1ff;\n  padding: 20px;\n\n  ul {\n    list-style: none;\n    line-height: 2;\n\n    li p {\n      display: flex;\n      gap: 10px;\n      color: #545454;\n      text-transform: capitalize;\n\n      span {\n        color: #004aad;\n        text-transform: capitalize;\n        font-weight: 700;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
