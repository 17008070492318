import React from "react";
import "./remainingbalance.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const RemainingBalance = ({ percentage }) => {

  return (
    <div className="progress-container">
      <CircularProgressbar
        strokeWidth={17.5}
        text={`${percentage}%`}
        value={percentage}
        styles={buildStyles({
          textSize: "16px",
          pathColor: `#096bfd`,
          textColor: "#003399",
          trailColor: "#d9d9d9",
          backgroundColor: "#3e98c7",
        })}
      />
    </div>
  );
};

export default RemainingBalance;
