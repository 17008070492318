import React, { useEffect, useState } from "react";
import "./delinquent.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Delinquent = ({ selectedDPDGroup, setSelectedDPDGroup }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchDPDGroup = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/loan/dpd-group`);
        setOptions(response.data);
      } catch (error) {
        console.error("Unable to fetch the DPD Group", error.message);
      }
    };
    fetchDPDGroup();
  }, []);

  return (
    <div className="delinquent-details">
      <h1>Delinquent Days</h1>
      <p>Select Delinquent Period</p>
      <select
        name="delinquent"
        id="delinquent"
        value={selectedDPDGroup}
        onChange={(e) => setSelectedDPDGroup(e.target.value)}
      >
        <option value="Select">Select</option>
        {options.map((option, index) => (
          <option key={index} value={option.DPDGroup}>
            {option.DPDGroup}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Delinquent;
