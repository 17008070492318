// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loan-application-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.loan-application-details h1 {
  color: #003399;
  font-size: 26px;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 1px;
}
.loan-application-details p {
  color: #003399;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 1px;
}
.loan-application-details select {
  width: 100%;
  height: 40px;
  background: #fff;
  color: #003399;
  border-radius: 10px;
  outline: none;
  border: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/loanapplication.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,0BAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,0BAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;AADJ","sourcesContent":[".loan-application-details {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  align-items: center;\n  justify-content: center;\n\n  h1 {\n    color: #003399;\n    font-size: 26px;\n    text-transform: capitalize;\n    font-weight: 700;\n    letter-spacing: 1px;\n  }\n\n  p {\n    color: #003399;\n    font-size: 18px;\n    text-transform: capitalize;\n    font-weight: 500;\n    letter-spacing: 1px;\n  }\n\n  select {\n    width: 100%;\n    height: 40px;\n    background: #fff;\n    color: #003399;\n    border-radius: 10px;\n    outline: none;\n    border: 1px solid #ccc;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
