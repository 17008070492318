import React, { useEffect, useState } from "react";
import "./callinformation.scss";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const CallInformation = ({ loanNum }) => {
  const [averageCall, setAverageCall] = useState(0);
  const [data, setData] = useState([]);

  const convertToHMS = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      try {
        const [callInfoRes, averageCallRes] = await Promise.all([
          axios.get(`${SERVER_URL}/call/info/${loanNum}`),
          axios.get(`${SERVER_URL}/call/average/${loanNum}`),
        ]);

        setData(callInfoRes.data);
        setAverageCall(averageCallRes.data[0].AverageCallDuration);
      } catch (error) {
        console.error("Unable to fetch call information!", error.message);
      }
    };

    if (loanNum) {
      fetchPaymentInfo();
    }
  }, [loanNum]);

  return (
    <>
      <div className="call-heading">
        <h1>
          <FaPhoneSquareAlt className="icon" /> Call Information
        </h1>
      </div>
      <div className="call-details-container">
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Call Date</th>
                <th>NumCall</th>
                <th>Call Duration</th>
              </tr>
            </thead>
            <tbody>
              {data.map((call, index) => (
                <tr key={index}>
                  <td>{new Date(call.CallDate).toLocaleDateString()}</td>
                  <td>{call.NumCalls}</td>
                  <td>{call.CallDuration} sec.</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="progress-bar-container">
          <div className="total-payment">
            <h4>Average Call Duration</h4>
            <h1>{convertToHMS(averageCall)}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallInformation;
